var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rec-block receive", attrs: { "bi-route": "rexiao" } },
    [
      _vm._m(0),
      _c(
        "ul",
        _vm._l(_vm.items.slice(0, 10), function(ite, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "rec-list",
              attrs: {
                "bi-click": "list",
                "data-index": index,
                "data-gid": ite.id || ite.goodsId,
                "data-pingtai": _vm.type
              },
              on: {
                click: function($event) {
                  return _vm.toDel(ite.id || ite.goodsId)
                }
              }
            },
            [
              _c("div", { staticClass: "fl" }, [
                _c("span", { staticClass: "tabs i0" }, [
                  _vm._v(_vm._s(index + 1))
                ]),
                _c("section", { staticClass: "fl img-cent" }, [
                  _c("img", {
                    staticStyle: {
                      background: "rgb(245, 245, 245)",
                      display: "block"
                    },
                    attrs: {
                      src: ite.mainPic + "_310x310.jpg",
                      alt: "",
                      width: "90",
                      height: "90"
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "content" }, [
                _c("p", [
                  ite.haitao === 1
                    ? _c("i", { staticClass: "ico tmht" })
                    : ite.tchaoshi === 1
                    ? _c("i", { staticClass: "ico tmcs" })
                    : ite.shopType === 1
                    ? _c("i", { staticClass: "ico tm" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(ite.dtitle || ite.title) + " ")
                ]),
                _c("div", { staticClass: "received" }, [
                  _vm._v(" 近2小时热度值 "),
                  _c("i", [
                    _vm._v(_vm._s(_vm._f("salesNum")(ite.twoHoursSales)))
                  ])
                ]),
                _c("span", { staticClass: "coupon" }, [
                  _c("i", [
                    _c("b", [_vm._v("¥")]),
                    _vm._v(_vm._s(ite.actualPrice))
                  ]),
                  _c("b", { staticClass: "old-pri" }, [
                    _vm._v("￥" + _vm._s(ite.originalPrice))
                  ])
                ])
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tit" }, [_c("span", [_vm._v("今日热销")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }