var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "font-family": "SimSun",
        width: "1200px",
        margin: "45px auto 30px",
        "font-size": "12px",
        "line-height": "26px",
        color: "#aaa"
      }
    },
    [
      _c(
        "p",
        {
          staticStyle: {
            color: "#666",
            "font-weight": "bold",
            "font-size": "14px",
            "margin-bottom": "10px",
            "font-family": "'宋体'"
          }
        },
        [_vm._v(" 内容声明： ")]
      ),
      _c("p", { staticClass: "void_border" }, [
        _vm._v(
          " 本网站是一家中立的导购网站，网站中的商品信息均来自于互联网。如商品信息不同，可能是商品信息未及时更新引起，所有商品信息请以淘宝/天猫店铺内为准。网站提醒用户，购买商品前注意谨慎核实相关信息。如用户对商品/服务的标题 、价格、详情等任何信息有任何疑问的，请在购买前与商品所属店铺经营者沟通确认。网站存在海量商品信息，如用户发现商品中有任何违法/违规信息，请 "
        ),
        _c(
          "span",
          { staticStyle: { color: "#5384ff" }, on: { click: _vm.toFeedback } },
          [_vm._v("立即反馈")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }