<template>
  <div class="rec-block receive" bi-route="rexiao">
    <div class="tit"><span>今日热销</span></div>
    <ul>
      <li
        class="rec-list"
        v-for="(ite, index) in items.slice(0, 10)"
        @click="toDel(ite.id || ite.goodsId)"
        :key="index"
        bi-click="list"
        :data-index="index"
        :data-gid="ite.id || ite.goodsId"
        :data-pingtai="type"
      >
        <div class="fl">
          <span class="tabs i0">{{ index + 1 }}</span>
          <section class="fl img-cent">
            <img
              :src="ite.mainPic + '_310x310.jpg'"
              alt=""
              width="90"
              height="90"
              style="background: rgb(245, 245, 245); display: block"
            />
          </section>
        </div>

        <div class="content">
          <!-- 默认天猫  tb 淘宝 -->
          <p>
            <i class="ico tmht" v-if="ite.haitao === 1"></i>
            <i class="ico tmcs" v-else-if="ite.tchaoshi === 1"></i>
            <i class="ico tm" v-else-if="ite.shopType === 1"></i>
            {{ ite.dtitle || ite.title }}
          </p>
          <div class="received">
            近2小时热度值 <i>{{ ite.twoHoursSales | salesNum }}</i>
          </div>
          <span class="coupon"
            ><i><b>¥</b>{{ ite.actualPrice }}</i
            ><b class="old-pri">￥{{ite.originalPrice}}</b></span
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    fun:Function,
    funLike:Function,
    type: String
  },
  methods: {
    toDel(id) {
      window.open(`?r=/l/d&id=${id}&from=dtk`,'_blank');
      // this.$routes.open({
      //   path: this.$route.path,
      //   query: {
      //     id: id,
      //     r: 'l/d'
      //   },
      // });
      this.fun();
      this.funLike();
      // window.scrollTo(0,0);
    },
  },
};
</script>

<style lang="less" scoped>
.rec-block {
  background-color: #fff;
  height: auto;
  max-height: none;
  .tit {
    padding: 8px 10px;
    font-size: 18px;
    height: 44px;
    line-height: 26px;
    box-sizing: border-box;
    margin: 5px 0;
    span {
      border-left: 3px solid #ff2e54;
      padding: 2px 0 0 10px;
    }
  }
  .rec-list {
    display: flex;
    padding: 16px 0;
    margin: 0 20px;
    cursor: pointer;
    &:hover .img-cent img {
      opacity: 0.8;
    }
    &:hover .content p {
      color: #ff2e54;
    }
    .fl {
      position: relative;
      z-index: 0;
      .tabs {
        position: absolute;
        left: 5px;
        top: 0;
        z-index: 0;
        z-index: 50;
        display: block;
        color: #fff;
        background: url('../../assets/detail/top.png');
        width: 18px;
        height: 22px;
        text-align: center;
        line-height: 20px;
        background-size: 100% 100%;
      }
      .img-cent {
        position: relative;
        width: 90px;
        height: 90px;
        text-align: center;
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .content {
      display: inline-block;
      width: 158px;
      margin-left: 10px;
      height: 90px;
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: 30px;
        font-size: 14px;
        display: block;
        line-height: 17px;
        color: #777;
        font-family: microsoft yahei;
        .tm {
          width: 15px;
          height: 15px;
          background: url("../../assets/search/prod_icon1.png") center/contain
            no-repeat;
          float: left;
          position: relative;
          top: 1px;
          margin-right: 5px;
        }
        .tmcs {
          width: 15px;
          height: 15px;
          background: url("../../assets/search/prod_icon3.png") center/contain
            no-repeat;
          float: left;
          position: relative;
          top: 1px;
          margin-right: 5px;
        }
        .tmht {
          width: 15px;
          height: 15px;
          background: url("../../assets/search/prod_icon2.png") center/contain
            no-repeat;
          float: left;
          position: relative;
          top: 1px;
          margin-right: 5px;
        }
      }
      .received {
        color: #fff;
        font-size: 12px;
        background: linear-gradient(90deg, #ff6a2c 0, #ff3f3e 100%);
        border-radius: 0 10px 10px;
        display: inline-block;
        padding: 1px 10px;
        margin-bottom: 18px;
        i {
          font-style: normal;
        }
      }
      .coupon {
        margin: 0 0 8px;
        i {
          font-size: 21px;
          font-family: Arial;
          font-style: initial;
          color: #fc4d52;
          line-height: 21px;
          position: relative;
          top: 2px;
          margin-left: 5px;
        }
        .old-pri {
          display: inline-block;
          color: #ababab;
          font-size: 12px;
          font-weight: 400;
          line-height: 0;
          border-top: 1px solid #ababab;
          position: relative;
          top: 2px;
          margin-left: 2px;
          font-family: Arial;
        }
      }
    }
  }
}
</style>