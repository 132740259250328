<template>
  <div class="loading_wrap" v-if="visible">
    <img :src="img" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean
  },
  data() {
    return {
      img: require("../assets/layout/loading.png"),
    };
  },
};
</script>

<style scoped lang="less">
.loading_wrap {
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 38px;
    animation: loading 1s linear 0s infinite;
  }
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
       transform: rotate(360deg);
    }
  }
}
</style>
