<template>
  <div
    style="
      font-family: SimSun;
      width: 1200px;
      margin: 45px auto 30px;
      font-size: 12px;
      line-height: 26px;
      color: #aaa;
    "
  >
    <p
      style="
        color: #666;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
        font-family: '宋体';
      "
    >
      内容声明：
    </p>
    <p class="void_border">
      本网站是一家中立的导购网站，网站中的商品信息均来自于互联网。如商品信息不同，可能是商品信息未及时更新引起，所有商品信息请以淘宝/天猫店铺内为准。网站提醒用户，购买商品前注意谨慎核实相关信息。如用户对商品/服务的标题
      、价格、详情等任何信息有任何疑问的，请在购买前与商品所属店铺经营者沟通确认。网站存在海量商品信息，如用户发现商品中有任何违法/违规信息，请
      <span style="color: #5384ff" @click="toFeedback">立即反馈</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    items: String,
  },
  data() {
    return {};
  },
  methods: {
    // 去反馈
    toFeedback() {
      this.$router.push({
        path: `/index/feedbackpc`,
        query: {
          id: this.$route.query.id || this.$route.query.goods_sign,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.void_border {
  border: 1px solid #fe2e54 !important;
  border-radius: 2px;
  padding: 5px;
  span{
    cursor: pointer;
  }
}
</style>